/* ==========================================================================
 *  Initialization Google maps
 * ======================================================================= */
function componentGoogleMaps() {
  $('.js-map').each(function () {
    var inst = $(this)
    var id = inst.attr('id')
    // var langitude = inst.data('langitude')
    // var longitude = inst.data('longitude')
    initMap(id)
  })
}

function initMap(id) {
  var markerImg
  function markerSet(image, width, height) {
    return new google.maps.MarkerImage(
      image,
      new google.maps.Size(width, height),
      // The origin for my image is 0,0.
      new google.maps.Point(0, 0),
      // The center of the image is 50,50 (my image is a circle with 100,100)
      new google.maps.Point(width / 2, height),
    )
  }

  var ua = window.navigator.userAgent
  var msie = ua.indexOf('MSIE ')

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    markerImg = markerSet('images/map-marker.png', 47, 70)
  } else {
    markerImg = markerSet('images/map-marker.svg', 47, 70)
  }

  var locations = [
    {
      langitude: '49.19222805470753',
      longitude: '16.615225234151843',
      marker: markerImg,
    },
    {
      langitude: '50.09851739670751',
      longitude: '14.369054752354439',
      marker: markerImg,
    },
  ]

  var bounds = new google.maps.LatLngBounds()

  var mapOptions = {
    center: new google.maps.LatLng(
      locations[0].langitude,
      locations[0].longitude,
    ),
    zoom: 17,
    zoomControl: true,
    disableDoubleClickZoom: false,
    mapTypeControl: false,
    scaleControl: true,
    scrollwheel: false,
    panControl: true,
    streetViewControl: false,
    draggable: true,
    overviewMapControl: false,
    overviewMapControlOptions: {
      opened: false,
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: [
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
          {
            saturation: 36,
          },
          {
            color: '#000000',
          },
          {
            lightness: 40,
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: '#000000',
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 17,
          },
          {
            weight: 1.2,
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 29,
          },
          {
            weight: 0.2,
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 18,
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 19,
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#000000',
          },
          {
            lightness: 17,
          },
        ],
      },
    ],
  }

  var mapElement = document.getElementById(id)
  var map = new google.maps.Map(mapElement, mapOptions)

  for (i = 0; i < locations.length; i++) {
    marker = new google.maps.Marker({
      icon: locations[i].marker,
      position: new google.maps.LatLng(
        locations[i].langitude,
        locations[i].longitude,
      ),
      map: map,
    })

    bounds.extend(marker.position)
  }

  map.fitBounds(bounds)
}
